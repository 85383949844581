body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  box-sizing: inherit;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  /* Padding for stacked Sticky NavFooter and FormFooter components */
  padding-bottom: 165px;
}
.atd-spinner {
  -webkit-animation: spinner 1s infinite;
          animation: spinner 1s infinite;
  margin: auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}
@-webkit-keyframes spinner {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spinner {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

